import { EmployeeTrainingsApi } from "@simbelapp/order-sdk";
import { defineStore } from "pinia";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { useEmployeeTrainingsApi } from "~~/composables/employee-trainings/useEmployeeTrainingsApi";
import { usePagination } from "~~/composables/paginations/usePagination";
import type { IEmployeeTrainingsStore } from "~~/utils/interfaces/employee-trainings";

export const useEmployeeTrainingsStore = defineStore("employeeTrainings", {
  state: (): IEmployeeTrainingsStore => {
    return {
      allEmployeeTrainings: null,
      employeeTraining: null,
      homePageEmployeeTrainings: [],

      showMySessionModal: false,
      showMyRequestModal: false,
      showEmployeeBookingDetails: false,
      offset: 0,
      maxOffset: 0,
      selectedCategoryTab: null,
      employeeTrainingFetchLoading: false,
    };
  },
  getters: {},
  actions: {
    softReset() {
      this.employeeTraining = null;
    },
    resetPaginate() {
      const paginate = usePagination();
      this.allEmployeeTrainings!.data = [];

      this.offset = 0;
      this.maxOffset = 0;
      paginate.paginateOptions.value.offset = this.offset;
    },
    async fetchAllEmployeeTrainings(noFilter?: Boolean) {
      this.employeeTrainingFetchLoading = true;
      const employeeTrainingsApi = useEmployeeTrainingsApi();
      const data = await employeeTrainingsApi.fetchAllEmployeeTrainings(noFilter);
      if (data) {
        this.allEmployeeTrainings = data;
      }
      this.employeeTrainingFetchLoading = false;
    },

    async fetchEmployeeTraining(myTrainingId: string) {
      const employeeTrainingsApi = useEmployeeTrainingsApi();
      const { data } = await employeeTrainingsApi.fetchEmployeeTraining(myTrainingId);
      if (data.value) {
        this.employeeTraining = data.value;
      }
    },

    async fetchHomePageEmployeeTrainings() {
      const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, EmployeeTrainingsApi);
      const data = await apiInstance.getHomePageEmployeeTrainings();
      if (data) {
        this.allEmployeeTrainings = {
          data,
          meta: null,
        };
      }
    },
  },
});
